import { Card, Col, message, Row } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import JsonInput from '../../components/inputs/JsonInput';
import Layout from '../../components/Layout';
import { QuickForm } from '../../components/QuickForm';
import { usePromiseResult } from '../../hooks/usePromiseResult';
import { useKeyData } from '../../modules/hasura/useKeyData';
import getRenders from '../../utils/getRenders';

const { formItems, buttons } = getRenders({
  formItems: {
    variables: {
      label: 'Variables',
      name: 'variables',
      children: <JsonInput />,
    },
  },
  buttons: {
    save: {
      children: 'Save',
      type: 'primary',
      htmlType: 'submit',
    },
  },
});

export default function Variables() {
  const data = useKeyData('dataSettings.variables');
  const [saveRequest, saveResult] = usePromiseResult(data.save);
  const initialValues = useMemo(() => {
    return { variables: data.data };
  }, [data.data]);

  useEffect(() => {
    if (saveResult.error) {
      message.error('Submit errors');
    } else if (saveResult.data) {
      message.success('Submit successful');
    }
  }, [saveResult.called]);

  const save = useCallback(
    (value: { variables: object }) => {
      return saveRequest({
        set: value.variables,
      });
    },
    [saveRequest]
  );

  return (
    <Layout>
      <Helmet title="Global variables" />
      <Card title="Global Variables" loading={data.loading && !data.data}>
        {data.loaded && (
          <QuickForm
            layout="vertical"
            initialValues={initialValues}
            onFinish={save}
          >
            {formItems.variables()}
            {buttons.save()}
          </QuickForm>
        )}
      </Card>
    </Layout>
  );
}
